<template>
  <div class="">

    <v-btn small color="" class="mr-1 border-dark px-4 py-1 " height="4vh" @click="dialog = true; resetData()">
      <v-icon class="pr-2">mdi-file-document-plus-outline</v-icon>
      <span class="text-capitalize">Bulk Upload</span>
    </v-btn>
    <lb-drawer v-model="dialog" :Maxwidth="(uploaddata.length > 0) ? '1665' : '700'" heading="Bulk Upload"
      :loading="loading">
      <template v-slot:body>
        <div>
          <div class="text-center mb-1 d-flex justify-space-between align-center">
            <p class="ma-0 pa-0" v-if="uploaddata.length === 0">Upload your document</p>
            <p class="ma-0 pa-0 font-weight-bold" v-else>Preview</p>
            <div class="d-flex align-center justify-center">
              <v-btn color="white" v-if="uploaddata.length > 0 || reUpload" small
                class="ml-2 d-flex align-center  justify-space-between " @click="reuploadDoc()"> <v-icon
                  class="pr-1">mdi-reload</v-icon>
                <p class="ma-0 pa-0"> Re-Upload</p>
              </v-btn>
              <v-btn small class="border-dark ml-2 d-flex align-center  justify-space-between "
                @click="$nova.downloadFile('', {}, '/v2/financialclose/masters/chartofaccount/getbulksubmittemplate')">
                <v-icon class="pt-1 pr-1">mdi-tray-arrow-down</v-icon>
                <p class="ma-0 pa-0"> Download Template</p>
              </v-btn>
            </div>
          </div>
          <div class="" v-if="uploaddata.length > 0">
            <v-checkbox @change="dataOnlyWithError = !dataOnlyWithError" label="Show only items with errors"
              class="my-1 mb-6" color="caccounting" hide-details></v-checkbox>
          </div>
          <div class="d-flex align-center justify-center mt-4" v-if="uploaddata.length === 0">
            <DragDropfile hidedetails :files="filesbulk" @update:files="updateFilesBulk" />
          </div>
        </div>
        <div v-if="uploaddata.length > 0" class="mt-3  radius-small">
          <v-simple-table dense class="radius-small border-on simple-table" style="max-height:73vh; overflow:scroll">
            <template v-slot:default>
              <thead>
                <th v-for="(item, index) in tableheader" :key="index"
                  class="text-left text-capitalize  pa-2 border-right-data grayBackground" :scope="item.value">{{
                  item.name }}</th>
              </thead>
              <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                <tr v-for="(v, k) in filteredData" :key="k" class="">
                  <td>{{ k + 1 }}</td>
                  <template v-for="(vv, kk) in v">
                    <td v-if="errorsonly[k][kk]" :key="kk" class="text-caption pa-0  border-right-data">
                      <div class="all-width all-height" style="border: 1px solid #F3B9BA">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-if="kk === 'checklistref'" class="d-inline-block">
                              <v-chip v-for="(va, ka) in vv" :key="ka" v-bind="attrs" v-on="on" color="error" small
                                outlined class="pl-2 mb-1 mr-1">
                                <v-icon>mdi-tag</v-icon>
                                <span class="pl-1">{{ va }}</span>
                              </v-chip>
                            </div>
                            <span v-else v-bind="attrs" v-on="on">{{ vv }}</span>
                          </template>
                          <span>{{ errorsonly[k][kk] }}</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td v-else :key="kk">
                      <div v-if="kk === 'checklistref' " class="d-flex">
                        <div v-for="(va, ka) in vv" :key="ka" small outlined class="ma-3">
                          <span class="caccounting-background caccounting--text pa-2 radius-mini">{{ va }}</span>
                        </div>
                      </div>
                      <div v-else-if="kk === 'location'" class="d-flex">
                        <div v-if="vv" small outlined class="ma-3">
                          <template  v-for="(va, ka) in vv.split(',')">
                            <span :key="ka" class="caccounting-background caccounting--text pa-2 ml-1 radius-mini">{{ va }}</span>
                          </template>
                        </div>
                        <span v-else class="ml-3 caccounting-background caccounting--text pa-2 radius-mini">Base</span>
                      </div>
                      <div v-else>{{ vv }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
          <p v-if="errorflag" class="error--text ma-0 pa-0 mt-4 text-caption">*These are fields where errors exist / information missing. Please rectify and re-upload to continue.</p>
        </div>
      </template>
      <template v-slot:actions>
        <!-- <div class="">
          <v-checkbox hide-details label="Leave out tasks with error" class="ma-0 pa-0 py-1" color="caccounting" @change="filterDataWithNoError()" v-model="UploadWithError"></v-checkbox>
        </div> -->
        <v-spacer></v-spacer>
        <v-btn class="border-dark" v-if="uploaddata.length > 0" @click="dialog = false; resetData()">Cancel</v-btn>
        <v-btn color="black" v-if="!errorflag" @click="uploadData" class="white--text">Upload</v-btn>
      </template>
    </lb-drawer>

    <!-- <lb-dialog v-model="showLoading" heading="Tasklist to Update" width="600" persistent>
      <template v-slot:body>
        <div>
          This will help you create new finance close tasks seamlessly..
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn small class="ml-2" color="fbcPrimary" @click="$nova.gotoLink({ path: 'checklist' });">Ok</v-btn>
        </div>
      </template>

    </lb-dialog> -->

  </div>
</template>

<script>
import DragDropfile from '../../../../views/Inputs/DragDropfile.vue';

export default {
  name: 'financialclose_component_chartofaccount_bulkupload',
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_chartofaccount",
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      errorflag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
      showLoading: false,
      filesbulk: [],
      dataOnlyWithError: false,
      dataWithNoErros: [],
      UploadWithError: false,
      tableheader: [

        {
          name: "S No",
          value: "sno"
        },
        {
          name: "Account No.",
          value: "glcode"
        },
        {
          name: "Account Name",
          value: "name"
        },
        {
          name: "Type",
          value: "type"
        },
        {
          name: "Category",
          value: "category"
        },
        {
          name: "Sub Category",
          value: "subcategory"
        },
        {
          name: "Tasks Linked",
          value: "checklistref"
        },
        {
          name: "Location",
          value: "location"
        },
      ],
      errorsonly:[]
    }
  },
  created() {
  },
  activated() {
  },
  components: {
    DragDropfile
  },
  methods: {
    init() {
    },
    readFile(key) {
      let file = this.filesbulk[0];
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['Account No', 'Name', 'Type', 'Category', 'Sub Category', 'Tasklist Reference', 'Location'];
        let possibletypes = ['Asset', 'Equity', 'Liability', 'Income', 'Expense', 'Others'];
        let possibletypeslower = possibletypes.map(x => x.toLowerCase());
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET",false);
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            this.errorflag = false;
            for (let i = 0; i < dt.length; i++) {
              if (i > 0) {
                const el = dt[i];
                let rowdata = { "glcode": el[0], "name": el[1], "type": el[2], "category": el[3], "subcategory": el[4], "checklistref": (el[5] || "").split(",").filter(x => x.replaceAll(/\s/g, '')), "location": el[6]||'' };
                let errors = {};
                let typeindex = possibletypeslower.indexOf((rowdata.type || "").toLowerCase());
                if (typeindex > -1) rowdata.type = possibletypes[typeindex];
                else errors["type"] = "Not a valid type";
                if ((rowdata.glcode || "").toString() === "") errors["glcode"] = " ";
                if ((rowdata.name || "").toString() === "") errors["name"] = " ";
                if ((rowdata.category || "").toString() === "") errors["category"] = " ";
                if ((rowdata.subcategory || "").toString() === "") errors["subcategory"] = " ";
                // if ((rowdata.location || "").toString() === "") errors["location"] = " ";
                if (Object.keys(errors).length > 0) this.errorflag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataWithNoErros = filedata
            this.dataerror = errordata;
            this.errorsonly = this.dataerror
            this.dialog = true;
          } else throw Error("Columns '" + headings.join("','") + "' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
          this.uploaddata = [];
          this.filesbulk = [];
          this.reUpload = true
          this.UploadWithError = false;
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      this.axios.post("/v2/financialclose/masters/chartofaccount/bulkadd", { data: this.dataWithNoErros }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Chartof Account uploaded successfully");
          setTimeout(() => {
            // this.showLoading = true;
          }, 5000);
          // this.showLoading = true;
          this.$nova.gotoLink({ path: 'checklist' });
        } else throw dt.data.message || "Error uploading Chartof Account";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateFilesBulk(newFilesBulk) {
      this.filesbulk = newFilesBulk;
      this.readFile('file')
      this.reUpload = true
      this.UploadWithError = false;
    },
    filterDataWithNoError() {
      this.dataWithNoErros = this.uploaddata.filter((v, k) => {
        return !Object.values(this.dataerror[k]).some(error => error);
      });
    },
    reuploadDoc() {
      this.uploaddata = []
      this.dataerror = [];
      this.UploadWithError = false;
    },
    reuploadfile(event) {
      this.uploadData = []
      this.filesbulk = event.target.files
      this.readFile('file')
    },
    resetData() {
      this.uploaddata = [];
      this.reUpload = false;
      this.UploadWithError = false;
      this.file = null;
      this.dataerror = [];
    },
  },
  watch: {
    dataOnlyWithError(newVal) {
    if (newVal) {
      this.errorsonly = this.dataerror.filter(item => Object.keys(item).length > 0);
    } else {
      this.errorsonly = this.dataerror;
    }
  }
  },
  computed:{
    filteredData() {
      if (this.dataOnlyWithError) {
      return this.uploaddata.filter((_, rowIndex) =>
          Object.values(this.dataerror[rowIndex]).some((error) => error)
        );
    }
    return this.uploaddata; // Return all rows if not filtering errors
      }
  }
}
</script>
